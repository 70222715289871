.ctot-navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0% 3%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  font-size: 14px;
}
.ctot-navbar .ctot-navbar-item {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
}
.ctot-navbar .ctot-navbar-item.bordered {
  border: 1.8px solid;
  padding: 0px 5px;
  border-radius: 1px;
}
.ctot-navbar .ctot-navbar-item:hover {
  color: #c4c5e9;
}
.ctot-navbar.ctot-navbar-stretched {
  justify-content: space-between !important;
}
@media (max-width: 767px) {
  .ctot-navbar {
    padding: 0px 20px;
  }
  .ctot-navbar.ctot-navbar-closed .ctot-navbar-content {
    padding: 10px;
    display: none;
  }
  .ctot-navbar.ctot-navbar-opened .ctot-navbar-content {
    flex-direction: column;
    background-color: #262430;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    padding: 20px 10px;
    border-bottom: 5px solid #4139a8;
  }
  .ctot-navbar.ctot-navbar-opened .ctot-navbar-content img {
    margin-bottom: 20px !important;
  }
  .ctot-navbar.ctot-navbar-opened .ctot-navbar-content div,
  .ctot-navbar.ctot-navbar-opened .ctot-navbar-content img {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .ctot-navbar .ctot-navbar-open-button {
    margin-left: auto;
  }
  .ctot-navbar .ctot-navbar-close-button {
    margin-top: 20px;
  }
}
@media (min-width: 768px) {
  .ctot-navbar .ctot-navbar-small-controls {
    display: none;
  }
}
.ctot-navbar img.logo {
  height: 40px;
  margin-right: 40px;
}
.footer {
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 0;
}
.footer-items {
  padding: 5% 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1a1920;
}
.footer-items .footer-item {
  flex: 50%;
  flex-shrink: 0;
}
.footer-copyright {
  width: 100%;
  text-align: center;
  font-size: 13px;
  font-weight: 200;
  padding: 15px 0;
  background-color: #2d2b37;
}
.ctot-docs-container {
  background-color: white;
  display: flex;
  padding: 2em;
  color: #262430 !important;
  gap: 4em;
}
.ctot-docs-container h1,
.ctot-docs-container h2,
.ctot-docs-container h3,
.ctot-docs-container h4 {
  color: #262430 !important;
  margin-top: 15px;
}
.ctot-docs-container h1 {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
  position: relative;
}
.ctot-docs-container h2 {
  font-weight: 600;
  font-size: 16px;
  position: relative;
  margin-top: 20px;
}
.ctot-docs-container h2::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  height: 0.5em;
  border-top: 1px solid #262430;
  opacity: 0.2;
}
.ctot-docs-container table tr,
.ctot-docs-container table th,
.ctot-docs-container table td {
  border: 0.5px solid grey;
  padding: 2px 5px;
}
.ctot-docs-container-menu {
  min-width: 22ch;
  flex-shrink: 1;
  height: 80vh;
  overflow-y: scroll;
}
.ctot-docs-container-content {
  display: block;
  overflow: hidden;
}
.ctot-docs-menu,
.ctot-docs-menu-group-content {
  display: flex;
  flex-direction: column;
}
.ctot-docs-menu .docs-menu-item,
.ctot-docs-menu-group-content .docs-menu-item {
  color: #2A247E;
  font-weight: 600;
}
.ctot-docs-menu .docs-menu-item:hover,
.ctot-docs-menu-group-content .docs-menu-item:hover {
  color: #3e35ba;
}
.ctot-docs-menu .docs-menu-item.active,
.ctot-docs-menu-group-content .docs-menu-item.active {
  color: #6f67d4;
}
.ctot-docs-menu-level-0 {
  display: block;
  font-size: 18px;
  text-transform: uppercase;
  color: #262430 !important;
  font-weight: 600 !important;
  margin-top: 15px;
}
.ctot-docs-menu-level-1 {
  font-size: 14px;
  color: #262430 !important;
  font-weight: 400 !important;
}
.ctot-docs-menu-level-1.active {
  font-weight: 600 !important;
}
.container-base-docs-menu {
  position: fixed;
  left: 0;
  overflow-x: scroll;
  min-width: 20%;
  padding: 5% 2%;
}
.container-base-docs-content {
  flex: 0.8;
  color: black !important;
  display: flex;
  flex-direction: row;
  font-family: -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
}
.container-base-docs-content h1,
.container-base-docs-content h2,
.container-base-docs-content h3,
.container-base-docs-content h4,
.container-base-docs-content h5,
.container-base-docs-content h6 {
  color: black;
  font-weight: 600;
}
.markdown {
  all: initial;
  color: black;
}
.container-base-docs-content-main {
  flex: 0.6;
  flex-grow: 1;
  padding: 5%;
}
.container-base-docs-content-code {
  overflow-x: scroll;
  flex: 0.4;
  padding: 5%;
}
.code {
  font-size: 11px;
}
.code span {
  font-size: 11px;
  line-height: 9px !important;
}
.code button {
  border: none;
  cursor: pointer;
}
.shadow,
.ctot-card {
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}
.shadow :hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
}
.ctot-card {
  width: 100%;
  max-width: 500px;
  background-color: #4547B5;
  border-radius: 4px;
  overflow: hidden;
}
.ctot-card h1,
.ctot-card h2,
.ctot-card h3,
.ctot-card h4 {
  color: #262430 !important;
}
.ctot-card-header {
  background-color: #fff;
  color: #262430;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 3% 5%;
}
.ctot-card-header > * {
  margin-top: 5px;
}
.ctot-card-header .ctot-card-title {
  background-color: #fff;
  color: #262430 !important;
  font-size: 140%;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.ctot-card-header .ctot-card-subtitle {
  font-size: 120%;
  text-transform: uppercase;
  font-weight: 400;
}
.ctot-card-header .ctot-card-title-subtitleSmall {
  flex: 1;
  width: 100%;
  font-weight: 200;
}
.ctot-card-header .ctot-card-title-headerAddon {
  margin-top: 10px;
}
.ctot-card-subtitle {
  background-color: #fff;
  color: #262430;
  font-weight: 500;
}
.ctot-card-content {
  background-color: #4547B5;
  color: #fff;
  font-weight: 400;
}
.ctot-card-content:not(:empty) {
  padding: 3% 5%;
}
.ctot-card-list-item {
  margin-top: 5px;
}
.landing-section {
  padding: 3% 3%;
  color: white;
}
@media (max-width: 767px) {
  .landing-section {
    text-align: center;
  }
}
.landing-section h1,
.landing-section h2,
.landing-section h3,
.landing-section h4 {
  color: hsla(0, 0%, 100%, 0.85);
  margin-top: 0;
  padding-top: 0;
}
.landing-section h1 {
  font-weight: 600;
  font-size: 38px;
  letter-spacing: 0.05rem;
}
.landing-section h2 {
  font-size: 20px;
}
.landing-section h3 {
  font-size: 20px;
}
.landing-section h4 {
  font-size: 20px;
  font-weight: 400;
}
.landing-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  padding-top: 50px;
}
.landing-section.landing-section-background {
  background-image: url("/public/images/header-background.jpg");
}
.landing-section.landing-section-contact {
  background-image: url("/public/images/contact-background.jpg");
  background-size: cover;
}
.landing-section.termsAndPolicy {
  padding: 10%;
  padding-top: 200px !important;
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
}
.landing-section.termsAndPolicy h2 {
  margin-top: 20px;
  margin-bottom: 0px;
}
.landing-section.landing-section-first {
  padding-top: 120px !important;
}
.landing-header-image {
  max-width: 100%;
  object-fit: cover;
  padding: 20px;
}
.landing-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.landing-container .blue {
  color: #6292f0;
}
.landing-container .yellow {
  color: #f0ca92;
}
.landing-container .center {
  text-align: center;
}
.landing-container > div:nth-child(2) {
  padding-top: 80px;
}
.strong {
  font-weight: 700;
}
.landing-header {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 80vh;
}
.landing-header-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0% 5%;
  max-width: 100%;
}
.landing-header-content:first-child {
  flex: 0.4;
  flex-grow: 1;
}
.landing-header-content:last-child {
  flex: 0.6;
  flex-grow: 1;
  padding-right: 5%;
  min-width: 50%;
}
@media (max-width: 767px) {
  .landing-header-content {
    width: 100%;
    padding: 0% 5%;
    text-align: center;
    align-items: center;
  }
  .landing-header-content .landing-app-links {
    justify-content: center;
    margin-bottom: 40px;
  }
  .landing-header-content > * {
    flex: 1;
    align-items: center;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .landing-integrations {
    flex-wrap: wrap;
  }
  .landing-integrations .landing-integration-item {
    display: flex;
    flex-direction: column-reverse;
  }
}
.landing-features {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
}
.landing-features .landing-features-image {
  max-width: 20ch;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.landing-features .landing-features-list {
  flex: 0.3;
  height: 100%;
}
@media (max-width: 767px) {
  .landing-features {
    flex-wrap: wrap;
  }
  .landing-features .landing-features-image {
    display: none;
  }
  .landing-features .landing-features-list {
    flex: 1;
    flex-shrink: 0;
  }
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYX8KVElMYYaJe8bpLHnCwDKhdTmdKZM.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYX7KVElMYYaJe8bpLHnCwDKhdTm2IdscQ.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYX7KVElMYYaJe8bpLHnCwDKhdTmvIRscQ.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYX-KVElMYYaJe8bpLHnCwDKhdTeEA.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYX7KVElMYYaJe8bpLHnCwDKhdTm5IVscQ.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYX7KVElMYYaJe8bpLHnCwDKhdTmyIJscQ.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYX7KVElMYYaJe8bpLHnCwDKhdTmrINscQ.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYX-KVElMYYaJe8bpLHnCwDKjbLeEA.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYX9KVElMYYaJe8bpLHnCwDKjR7_MIY.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYX9KVElMYYaJe8bpLHnCwDKjXr8MIY.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYXgKVElMYYaJe8bpLHnCwDKtdY.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYX9KVElMYYaJe8bpLHnCwDKjSL9MIY.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYX9KVElMYYaJe8bpLHnCwDKjQ76MIY.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v19/zYX9KVElMYYaJe8bpLHnCwDKjWr7MIY.ttf) format('truetype');
}
body {
  margin: 0;
  padding: 0;
  font-family: "IBM Plex Sans", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #262430;
  color: white;
  font-weight: 400;
  font-size: 15px;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
}
