.ctot-navbar-dashboard {
  font-family: "IBM Plex Sans", sans-serif !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0px;
  z-index: 1;
  font-size: 14px;
  background: linear-gradient(120deg, #2a247e 0%, #4547b5 100%);
  justify-content: space-between;
  padding: 10px 20px;
}
.ctot-navbar-dashboard img {
  height: 30px;
  margin-right: 30px;
}
.ctot-navbar-dashboard .ctot-navbar-dashboard-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.ctot-navbar-dashboard .ctot-navbar-dashboard-item {
  margin-left: 25px;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.8px;
}
.ctot-navbar-dashboard .ctot-navbar-dashboard-item .icon {
  margin-left: 5px;
}
@media (max-width: 767px) {
  .ctot-navbar-dashboard .ctot-navbar-dashboard-item {
    margin-left: 0px;
  }
}
.ctot-navbar-dashboard .ctot-navbar-dashboard-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -3px;
}
@media (max-width: 767px) {
  .ctot-navbar-dashboard {
    padding: 10px;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .ctot-navbar-dashboard .ctot-navbar-dashboard-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
    text-align: center;
  }
  .ctot-navbar-dashboard .ctot-navbar-dashboard-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
  }
  .ctot-navbar-dashboard .ctot-navbar-dashboard-logo {
    display: none;
  }
  .ctot-navbar-dashboard .ctot-navbar-dashboard-item-search {
    margin-left: 3px;
  }
  .ctot-navbar-dashboard .ctot-navbar-dashboard-item-display {
    margin-right: 3px;
  }
  .ctot-navbar-dashboard .ctot-navbar-dashboard-item-profile {
    text-align: right;
  }
}
.ctot-navbar-dashboard .ctot-navbar-dashboard-item-search {
  margin-right: auto;
}
.ctot-navbar-dashboard .ctot-navbar-dashboard-item-display {
  margin-right: auto;
}
.ctot-navbar-dashboard .ctot-navbar-dashboard-item-profile {
  margin-left: auto;
}
@media (max-width: 767px) {
  .ctot-navbar-dashboard {
    position: inherit;
    flex-wrap: wrap;
    padding: 10px;
    width: 100%;
    overflow: scroll;
  }
  .ctot-navbar-dashboard .anticon-down {
    display: none;
  }
  .ctot-navbar-dashboard .ant-input-search {
    width: 250px !important;
    margin-left: 0px !important;
    margin-right: 15px !important;
  }
  .ctot-navbar-dashboard .ant-input-group-wrapper {
    padding-left: 0 !important;
  }
  .ctot-navbar-dashboard .ctot-navbar-dashboard-group {
    width: 100%;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.footer {
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 0;
}
.footer-items {
  padding: 5% 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1a1920;
}
.footer-items .footer-item {
  flex: 50%;
  flex-shrink: 0;
}
.footer-copyright {
  width: 100%;
  text-align: center;
  font-size: 13px;
  font-weight: 200;
  padding: 15px 0;
  background-color: #2d2b37;
}
.ctot-dashboard-login a {
  color: #262430;
  text-decoration: none;
  font-weight: 600;
}
.ctot-dashboard-login-card {
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  color: #262430;
  border-radius: 3px;
  overflow: hidden;
  padding: 3% 5%;
  text-align: center;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.03);
  border: 1px solid #f0f0f0;
}
.ctot-dashboard-login-card-header {
  padding-bottom: 20px;
}
.ctot-dashboard-login-card-content {
  padding-top: 20px;
}
.flight-timeline {
  padding-top: 30px;
  flex: 1;
}
.flight-timeline * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
  background: none;
  font: inherit;
  color: inherit;
  text-decoration: none;
  list-style: none;
}
.flight-timeline .timeline-container {
  position: relative;
  flex: 1;
  overflow: hidden;
  width: 100%;
}
.flight-timeline .timeline-container::-webkit-scrollbar {
  height: 6px;
}
.flight-timeline .timeline-container::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 3px;
}
.flight-timeline .timeline-container::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
}
.flight-timeline .timeline-container::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.3);
}
.flight-timeline .timeline {
  position: relative;
  height: 100px;
  display: grid;
  grid-template-columns: 7ch repeat(7, minmax(9ch, 1fr)) 7ch;
  gap: 3px;
  /* Adjust spacing between items */
}
.flight-timeline .timeline.timeline-8 {
  grid-template-columns: 7ch repeat(8, minmax(9ch, 1fr)) 7ch;
}
.flight-timeline .timeline.timeline-9 {
  grid-template-columns: 7ch repeat(9, minmax(9ch, 1fr)) 7ch;
}
.flight-timeline .timeline-line {
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 5%, rgba(255, 255, 255, 0.1) 10%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.1) 90%, rgba(255, 255, 255, 0) 95%);
  z-index: 1;
}
.flight-timeline .airport-container {
  margin-top: 19px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: auto;
}
.flight-timeline .airport-code {
  font-weight: bold;
  font-size: 18px;
  color: #4dabf7;
  text-shadow: 0 0 10px rgba(77, 171, 247, 0.3);
}
.flight-timeline .airport-code-stand {
  font-weight: bold;
  font-size: 12px;
  color: #4dabf7;
  text-shadow: 0 0 10px rgba(77, 171, 247, 0.3);
  border: 1px solid #4dabf7;
  border-radius: 3px;
  padding: 2px 4px;
  line-height: 12px;
  margin-top: 1px;
}
.flight-timeline .eet-container {
  grid-column-end: span 2;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.flight-timeline .eet-container .eet-label {
  padding: 2px 8px;
  margin-top: 2px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 500;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 12px;
  grid-column-end: span 2;
}
.flight-timeline .time-point {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  position: relative;
  top: 0px;
}
.flight-timeline .time-point::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  bottom: 10px;
  background: rgba(255, 255, 255, 0.02);
  border-radius: 8px;
  opacity: 0;
  transition: opacity 0.2s ease;
}
.flight-timeline .time-point:hover::before {
  opacity: 1;
}
.flight-timeline .time-point .time {
  width: auto;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 2px;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
  margin-top: 40px;
}
.flight-timeline .time-point .time.red-time {
  color: #ff6b6b;
  text-shadow: 0 0 10px rgba(255, 107, 107, 0.3);
}
.flight-timeline .time-point .time span {
  display: inline-block;
  width: 12px;
  text-align: center;
}
.flight-timeline .time-point .tick {
  width: 2px;
  height: 8px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.6) 100%);
  margin: 0;
  border-radius: 1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: absolute;
  top: 32px;
  transform: translateX(-50%);
}
.flight-timeline .time-point .label {
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.drawer .drawer-title {
  font-size: 18px;
  font-weight: 600;
}
.drawer .airport-code {
  color: #4dabf7;
  font-weight: bold;
  text-shadow: 0 0 10px rgba(77, 171, 247, 0.3);
}
.drawer .title {
  color: rgba(240, 240, 240, 0.9);
  text-shadow: 0 0 10px rgba(240, 240, 240, 0.3);
}
.drawer .divider {
  background-color: rgba(240, 240, 240, 0.6);
}
.drawer .last-update {
  padding: 2px 8px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 500;
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 100;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v21/zYXEKVElMYYaJe8bpLHnCwDKhdTEG46kmUZQCX598fQbM4jw8V78x9OWIhqbQqg9.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 200;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v21/zYXEKVElMYYaJe8bpLHnCwDKhdTEG46kmUZQCX598fQbM4jw8V78x9OWIhobQ6g9.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 300;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v21/zYXEKVElMYYaJe8bpLHnCwDKhdTEG46kmUZQCX598fQbM4jw8V78x9OWIhrFQ6g9.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v21/zYXEKVElMYYaJe8bpLHnCwDKhdTEG46kmUZQCX598fQbM4jw8V78x9OWIhqbQ6g9.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 500;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v21/zYXEKVElMYYaJe8bpLHnCwDKhdTEG46kmUZQCX598fQbM4jw8V78x9OWIhqpQ6g9.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 600;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v21/zYXEKVElMYYaJe8bpLHnCwDKhdTEG46kmUZQCX598fQbM4jw8V78x9OWIhpFRKg9.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: italic;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v21/zYXEKVElMYYaJe8bpLHnCwDKhdTEG46kmUZQCX598fQbM4jw8V78x9OWIhp8RKg9.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 100;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v21/zYXGKVElMYYaJe8bpLHnCwDKr932-G7dytD-Dmu1swZSAXcomDVmadSD6lhzAA.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 200;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v21/zYXGKVElMYYaJe8bpLHnCwDKr932-G7dytD-Dmu1swZSAXcomDVmadSDallzAA.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v21/zYXGKVElMYYaJe8bpLHnCwDKr932-G7dytD-Dmu1swZSAXcomDVmadSDtFlzAA.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v21/zYXGKVElMYYaJe8bpLHnCwDKr932-G7dytD-Dmu1swZSAXcomDVmadSD6llzAA.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 500;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v21/zYXGKVElMYYaJe8bpLHnCwDKr932-G7dytD-Dmu1swZSAXcomDVmadSD2FlzAA.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v21/zYXGKVElMYYaJe8bpLHnCwDKr932-G7dytD-Dmu1swZSAXcomDVmadSDNF5zAA.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexsans/v21/zYXGKVElMYYaJe8bpLHnCwDKr932-G7dytD-Dmu1swZSAXcomDVmadSDDV5zAA.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexmono/v19/-F6rfjptAgt5VM-kVkqdyU8n1ioStndl.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexmono/v19/-F6sfjptAgt5VM-kVkqdyU8n1ioSGlZFhw.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexmono/v19/-F6sfjptAgt5VM-kVkqdyU8n1ioSflVFhw.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexmono/v19/-F6pfjptAgt5VM-kVkqdyU8n1ioq0g.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexmono/v19/-F6sfjptAgt5VM-kVkqdyU8n1ioSJlRFhw.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexmono/v19/-F6sfjptAgt5VM-kVkqdyU8n1ioSClNFhw.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexmono/v19/-F6sfjptAgt5VM-kVkqdyU8n1ioSblJFhw.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexmono/v19/-F6pfjptAgt5VM-kVkqdyU8n3kwq0g.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexmono/v19/-F6qfjptAgt5VM-kVkqdyU8n3uAL8lc.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexmono/v19/-F6qfjptAgt5VM-kVkqdyU8n3oQI8lc.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexmono/v19/-F63fjptAgt5VM-kVkqdyU8n5ig.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexmono/v19/-F6qfjptAgt5VM-kVkqdyU8n3twJ8lc.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexmono/v19/-F6qfjptAgt5VM-kVkqdyU8n3vAO8lc.ttf) format('truetype');
}
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ibmplexmono/v19/-F6qfjptAgt5VM-kVkqdyU8n3pQP8lc.ttf) format('truetype');
}
body {
  margin: 0;
  padding: 0;
  font-family: "IBM Plex Mono", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #262430;
  color: white;
  font-weight: 400;
  font-size: 15px;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: always;
}
.dashboard-flight-table * {
  font-family: "IBM Plex Mono", "mono";
}
a {
  color: #4dabf7;
}
